.wrapper-class {
  border: 1px solid lightgray;
  min-height: 400px;
  overflow: auto;
  max-height: 400px;
}

.ql-container.ql-snow {
  height: 300px;
}

.ql-container.ql-disabled .ql-tooltip {
  display: none;
}

.read-more-link {
  cursor: pointer;
  font-size: 12px;
  color: blue;
}

.truncate-content {
  max-height: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.truncate-content .ql-editor {
  padding: 2px;
}

/* .truncate-content::after {
  content: '\02026';
  color: #000;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 3em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%, #fff);
  text-align: right;
  vertical-align: baseline;
} */

.quill img {
  max-width: 100%;
}

.ql-tooltip {
  left: unset !important;
}

.drawer-comment .ql-editor {
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
}

.drawer-comment .ql-clipboard {
  position: fixed !important;
}

.drawer-comment .ql-container {
  height: auto !important;
}

.drawer-comment .ql-editor {
  height: auto !important;
}

.add-comment {
  display: flex;
  flex-direction: column-reverse;
}

.add-comment .ql-container {
  height: auto;
  min-height: 70px;
  max-height: 150px;
  overflow: auto;
  border-top: 1px solid #ccc !important;
  border-bottom: 0px !important;
  font-size: 16px;
}

.add-step .ql-container {
  min-height: 50px;
  height: auto;
  max-height: 200px;
  overflow: auto;
  font-size: 16px;
}

.comment .ql-editor {
  padding: 2px;
}

.comment .ql-container {
  font-size: 16px;
}