.login-layout-container {
  min-height: 100vh;
  background-color: #231f20;
}

.login-form {
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px !important;
}

.textBox-style {
  background: #ffff;
  border-radius: 1px;
  font-size: 15px;
}

.input-icon-style {
  color: "#666666";
}

.back-to-login {
  font-size: 13px;
}

.arrow-icon {
  font-size: 14px !important;
  vertical-align: middle;
}

.reset-text-link {
  color: #ffff !important;
  font-size: 14px;
  line-height: 20px;
}

.alert-div {
  height: auto;
  min-height: 55px;
}

.custom-alert {
  padding: 2px 6px !important;
  font-size: 12.5px !important;
  font-weight: 550;
}

.send-btn {
  background: #f8b312 !important;
  border-radius: 0px !important;
}

.send-btn:hover {
  background-color: #f9bf39;
}

.back-login-link {
  font-size: 13px;
  color: #ffff !important;
  cursor: pointer;
}

.back-login-link label {
  cursor: pointer !important;
}

.text-msg-label {
  font-size: 13px;
  color: #ffff !important;
}

.link-txt {
  font-size: 13px !important;
}

.signup-link {
  text-align: center;
  margin-top: 5px;
}

.reset-password-lbl {
  color: #ffff;
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.input-lbl {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 10px;
}

.email-sent-flash-message {
  text-align: center;
  color: #ffff !important;
  font-size: 15px;
  line-height: 20px;
}
