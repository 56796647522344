:root {
  --child-height: 10px;
}

.title {
  font-size: 13px;
  color: #808080;
  text-transform: uppercase;
}

.subTitle {
  font-size: 12px;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.addIcon {
  color: #d49a3a;
  font-size: 15px;
  vertical-align: middle;
}

.entityImg {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

.button {
  cursor: pointer;
  margin-left: 5px;
}

.areaList {
  overflow: auto;
  max-height: 85vh;
  position: relative;
}

.budgetAreaList {
  overflow: auto;
  max-height: 45vh;
  position: relative;
}

.visibilityContainer {
  color: #666666;
  font-size: 14px;
}

.areaTitleContainer {
  color: #666666;
  font-size: 14px;
  font-weight: 700;
}

.hideVisibilityDiv {
  display: none !important;
}

.noAreaSelected {
  color: #666666;
  font-size: 14px;
  margin-top: 18px !important;
  margin-left: 25px !important;
  font-style: italic;
}

.noAreaAvailable {
  color: #666666;
  font-size: 14px;
  margin-top: 18px !important;
  margin-left: 25px !important;
  font-style: italic;
}

.area-loading {
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.deleteTitle {
  font-size: 12px;
  font-weight: 400;
  vertical-align: middle;
}

/* .divider {
  margin-top: 15px;
  margin-bottom: 15px;
} */

.longAreaContainer {
  background: #f8f8f9;
  height: 100vh;
}

.removeIcon {
  display: none;
}

.attach-milestone:hover .removeIcon {
  display: flex;
}

.autocomplete-grid:hover {
  background: lightgray !important;
}

.autocomplete-grid:hover .selectedItem {
  background-color: rgb(222, 235, 255) !important;
}

.inputAutoComplete .MuiOutlinedInput-root {
  border-radius: 0;
  padding: 6px;
}

.height-preserving-container:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

.location-row {
  padding: 8px;
}

.location-row:hover {
  background-color: #f5f5f5 !important;
}

.location-row:hover .display-icons {
  visibility: visible;
}

.display-icons {
  visibility: hidden;
}
