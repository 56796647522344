/* button button>span.MuiButton-label {
    background: #F8B312 !important;
    border-radius: 0px !important;
    color: #ffff !important
}

button:hover {
    background-color: #f9bf39;
} */

.root {
  overflow: hidden;
}

.custom-btn {
  font-size: 11px !important ;
  color: black !important;
  font-weight: 400;
  box-shadow: none;
  padding: 4px 16px;
  text-transform: unset;
  border: 2px solid #c4c4c4 !important;
  background-color: #f5f5f5 !important;
  border-radius: 0px !important;
}

.custom-btn:hover {
  background-color: #f5f5f5;
}

.hex {
  margin-top: 30px !important;
  width: 49px !important;
  height: 23px !important;
  background-color: #ff6600;
  border-color: #ff6600;
  position: relative !important;
  display: -webkit-inline-box !important;
}
.hex:before {
  content: ' ' !important;
  width: 0 !important;
  height: 0 !important;
  top: -14px !important;
  border-bottom: 14px solid !important;
  border-color: inherit !important;
  border-left: 24px solid transparent !important;
  border-right: 25px solid transparent !important;
  position: absolute !important;
}
.hex:after {
  content: '' !important;
  width: 0 !important;
  position: absolute !important;
  bottom: -14px !important;
  border-top: 14px solid !important;
  border-color: inherit !important;
  border-left: 24px solid transparent !important;
  border-right: 25px solid transparent !important;
}

.label {
  font-size: 12px;
}

.btn-image {
  width: 15px !important;
  height: 20px !important;
  margin-right: 5px !important;
  display: inline-block !important;
  vertical-align: middle !important;
}
