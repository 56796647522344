.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-break {
  break-before: page;
}

/* .MuiGrid-container{
  display: block !important;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tree-view {
  width: 100%;
  padding: inherit;
  list-style: none;
}

.budget-area-tree-view {
  width: 100%;
  padding: inherit;
  list-style: none;
}

a.toggle-btn.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #555;
  vertical-align: top;
  margin-top: 10px;
}

a.toggle-btn.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 4px solid #555;
  border-bottom: 4px solid transparent;
  vertical-align: top;
  margin-top: 7px;
}

.highlighted-row-css {
  border-top: 0.25px solid #11a9d9 !important;
  border-bottom: 0.25px solid #11a9d9 !important;
  border-right: 0px !important;
  background-color: #bad9e2 !important;
  display: flex;
  height: 35px;
}

.highlighted-row-css .moreHoriz {
  visibility: visible;
}

.collapse-tree {
  display: none !important;
}

.budget-list {
  display: block;
}

.pointer {
  cursor: pointer;
}

.label-color {
  color: #8e8e90;
}

.label {
  font-size: 13px !important;
  max-width: 90%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.blur {
  opacity: 0;
}

a.big-arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 7px solid #555;
  border-bottom: 6px solid transparent;
  vertical-align: top;
  margin-top: 0px;
}

a.big-arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #555;
  vertical-align: top;
  margin-top: 0px;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-13 {
  padding-right: 13px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.list-row:hover {
  background-color: #fafafa;
}

.right-click-menu {
  background-color: #131b27;
  color: white;
  font-size: 13px;
  border-radius: 4px;
  min-width: 140px;
}

.right-click-menu-item {
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.height-preserving-container:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

.avatar {
  font-size: 11px !important;
  width: 28px !important;
  height: 26px !important;
  margin-right: 5px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-center {
  display: 'flex';
  align-items: 'center';
  align-content: 'center';
}

.loader {
  width: 40px;
  height: 40px;
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.gridRow {
  fill: #fff;
}

.gridLine {
  stroke: #e9eaeb;
}

.displayNone {
  display: none !important;
}

.schedulerRow {
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 13px;
  flex-wrap: unset;
  align-items: center;
  background-color: white;
  display: -webkit-inline-box;
  border-bottom: 1px solid #e9eaeb;
}

/* .task-todo {
    background-color: #B3B3B3;
}

.task-active {
    background-color: #447FF2;
}

.task-complete {
    background-color: #1EB980;
}

.task-paused {
    background-color: #FCC034;
}

.task-blocked {
    background-color: '#FE6969S';
}

.pace-0 {
    background-color: #B3B3B3;
}

.pace-less-than-0 {
    background-color: '#FE6969S';
}

.pace-greater-than-0 {
    background-color: #1EB980;
} */

.static-height {
  height: 35px !important;
}

.flex {
  display: flex !important;
}

.align-item-center {
  align-items: center;
}

.task-card-zoom {
  transition: transform 0.2s;
}

.cursor-default {
  cursor: default;
}

@page {
  margin: 0.5in;
}

.blur {
  opacity: 0.3;
}
.webKitOverflowScrollingAuto {
  -webkit-overflow-scrolling: 'auto';
  scrollbar-color: #1eb980 #00000010;
  scrollbar-width: thin;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.box-shadow {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

body {
  overscroll-behavior: none;
}
