.gantt_resource_marker {
  box-sizing: border-box;
  padding: 4px;
}

.resource_cell {
  height: 100%;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
}

.resource_cell--over-capacity {
  background-color: rgba(220, 0, 0, 0.75);
}

.resource_cell--under-capacity {
  background-color: rgba(254, 192, 105, 1);
}

.resource_cell--at-capacity {
  background-color: rgba(30, 185, 128, 0.75);
}

.resource_cell--unallocated {
  background-color: transparent;
}
