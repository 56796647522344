/* Fixed first column */
:root {
  --gantt-frozen-column-scroll-left: 0px;
  --gantt-frozen-column-resizer-left: 0px;
  --blackhyve-purple: #d1a8ff;
}

.gantt_container {
  border: none;
}

#gantt-container-blackhyve .gantt_task_grid .gantt_cell:nth-child(1),
#gantt-container-blackhyve .gantt_task_grid .gantt_grid_head_cell:nth-child(1) {
  background-color: #fff;
  position: relative;
  left: var(--gantt-frozen-column-scroll-left);
  z-index: 4;
}

.gantt_cell .capitalize {
  text-transform: capitalize;
}

.gantt_grid_head_cell:nth-child(1) {
  z-index: 2;
}

/* Placeholder opacity for fixed column */
.gantt_row_placeholder div {
  opacity: 1;
}

.gantt_row_placeholder .gantt_cell>div,
.complete_task .gantt_cell:not([data-column-name='text'])>div,
.readonly.gantt_row .gantt_cell {
  color: rgb(171 171 171 / 80%);
}

.gantt_container {
  border-top: none !important;
}

.gantt_layout_cell {
  box-sizing: content-box;
}

/* .gantt_layout_cell.grid_cell {
  border-right: 1px solid#d7d7d7;
} */
.gantt_layout_y.gantt_layout_cell_border_right {
  border-right: none;
}

.gantt_layout_cell_border_right.gantt_resizer {
  width: 4px !important;
  margin-left: 2px;
  background: #d7d7d7 !important;
}

.gantt_resizer_x .gantt_resizer_x {
  margin-left: 0px;
}

.gantt_tree_content {
  display: flex;
  align-items: center;
}

.gantt_grid_data .gantt_row {
  border-bottom: 1px solid #e9eaeb;
}

.gantt_grid_data .gantt_project_grid_row .gantt_cell {
  font-weight: 900;
}

.gantt_grid_data .gantt_cell:not(.gantt_last_cell) {
  border-right: 1px solid #e9eaeb;
}

.gantt_grid_head_cell_two_lines {
  display: block;
  line-height: 17px;
}

.gantt_task .gantt_task_scale .gantt_scale_line .gantt_scale_cell {
  font-size: 10px;
  text-align: center;
  color: #525254;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gantt_scale_cell,
.gantt_task_cell {
  border-right: 1px solid #e9eaeb;
}

/*-----*/

.gantt_scale_cell.weekend,
:not(.gantt_task_row[data-task-id^='location']):not(.gantt_task_row[data-task-id^='zone'])>.gantt_task_cell.weekend {
  background-color: #f5f5f5;
}

.gantt_grid_scale,
.gantt_task_scale {
  border-bottom: 1px solid #e9eaeb;
}

div.gantt_grid_scale:after,
div.gantt_scale_line:last-child:after {
  box-shadow: none;
}

.gantt_grid .gantt_grid_scale,
.gantt_grid .gantt_row {
  border-right: none;
}

.gantt_row,
.gantt_task_row {
  border-bottom: 1px solid #e9eaeb;
}

.baseline {
  position: absolute;
  margin-top: -7px;
  height: 5px;
  z-index: 4;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.4);
}

.gantt_task_baseline {
  position: absolute;
  background-color: #9f9f9f;
}

/* TASK BAR */

.gantt_task_line {
  border-radius: 2px;
  border: unset;
  color: #fff;
  /* padding: 9px 0px; */
}

.gantt_task_line .gantt_task_content {
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gantt_grid .gantt_grid_scale .gantt_grid_head_cell {
  border-right: 1px solid #e9eaeb;
}

/* .gantt_grid .gantt_grid_head_cell {
    background-color: #fff;
  } */
.gantt_grid_column_resize_wrap .gantt_grid_column_resize {
  background-color: #e9eaeb;
}

.gantt_task_line.complete_task .gantt_task_drag,
.gantt_task_line.active_task .gantt_task_drag.task_left,
.gantt_task_line.dependent_task .gantt_task_drag.task_left {
  display: none;
}

.gantt_task_line.scheduled_dates_locked .gantt_task_drag.task_left,
.gantt_task_line.scheduled_dates_locked .gantt_task_drag.task_right {
  display: none;
}

.gantt_task_line .gantt_link_point {
  border-color: #c4c4c4;
}

.gantt_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#gantt-container-blackhyve .jobwalk-past-due-icon svg {
  color: red;
}

#gantt-container-blackhyve .jobwalk-due-icon svg {
  color: orange;
}

.gantt_cell .cell_disabled {
  color: rgb(171 171 171 / 80%);
}

.read-only-gantt .readonly.gantt_row .gantt_cell,
.read-only-gantt .gantt_cell .cell_disabled {
  color: rgba(0, 0, 0, 0.8);
}

.gantt_cell svg:not(.MuiButtonBase-root svg):not(.cell_disabled svg) {
  color: rgb(171 171 171 / 80%);
}

.gantt_cell:not([data-column-name='action']) .cell_disabled svg,
.readonly .gantt_cell:not([data-column-name='action']) svg {
  color: rgb(171, 171, 171, 0.4);
}

.gantt_link_arrow,
.gantt_link_arrow,
.gantt_task_link .gantt_line_wrapper {
  z-index: 0;
}

.readonly .gantt_cell,
.readonly .gantt_link_arrow,
.readonly .gantt_line_wrapper {
  cursor: default;
}

.readonly .gantt_cell_tree {
  pointer-events: all;
}

.gantt_tree_custom_icon svg {
  display: block;
}

/* .gantt_grid_editor_placeholder :focus-visible {
    outline: 0;
  }
  
  .gantt_grid_editor_placeholder input {
    border: 0;
    border-bottom: 1px solid #e9eaeb;
    border-right: 1px solid #e9eaeb;
  } */
/* BULK EDIT MENU CSS */

.bulk-edit-menu.paper {
  border-radius: 15px;
  position: fixed;
  max-width: 90%;
  bottom: 25px;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: #1eb980;
  padding: 0px 10px;
  color: #fff;
  animation: '$grow 200ms ease-in alternate';
  transition: 'translate';
  z-index: 10;
}

.bulk-edit-menu .divider {
  margin: 5px 10px;
  height: 30;
  background-color: #fff;
}

.bulk-edit-menu .arrow-icon {
  font-size: 18px;
}

.task-row-checkbox {
  padding: 0 !important;
}

.gantt_tooltip {
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding: 4px 8px;
  font-size: 0.6875rem;
  max-width: 300px;
  margin: 2px;
  overflow-wrap: break-word;
  font-weight: 500;
}

.task-row-action-column,
.task-row-action-column.MuiGrid-item {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  margin-left: 6px;
  margin-right: 6px;
}

.task-row-hierarchy {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
  /* color: rgb(171 171 171 / 80%); */
}

.gantt_marker.today-marker {
  background-color: #1eb980;
  z-index: 12;
}

.gantt_marker .gantt_marker_content {
  font-size: 9px;
  padding: 2px 3px;
}

.gantt_task_line.gantt_critical_task {
  background-color: #ff5252 !important;
  border: 1px solid #ff5252 !important;
}

.gantt_task_line.on_pace_task {
  background-color: #1eb980 !important;
  border: 1px solid #1eb980 !important;
}

.gantt_task_line.behind_pace_task {
  background-color: #fec069 !important;
  border: 1px solid #fec069 !important;
}

.gantt_task_line.no_pace_task {
  background-color: #ccc !important;
  border: 1px solid #ccc !important;
}

/* MUI THEME CLASSES */
/* .MuiButton-containedSecondary {
    background-color: #1eb980 !important;
  } */

.gantt_container .MuiFormHelperText-contained {
  font-size: 11px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.task-bar-label {
  font-size: 10px;
  color: black;
  line-height: 1.5;
  display: flex;
  align-items: center;
  letter-spacing: 0.00938em;
}

.gantt_task_line.gantt_project.gantt_bar_project {
  background-color: #ccc;
  border-radius: 0px;
  border-color: none !important;
  border: 0px;
  opacity: 0.6;
  color: white !important;
  clip-path: polygon(-300px 0%,
      -300px 100%,
      0 100%,
      5px 70%,
      calc(100% - 5px) 70%,
      100% 100%,
      calc(100% + 300px) 100%,
      calc(100% + 300px) 0%);
}

.gantt_task_line.gantt_project.gantt_bar_project .gantt_task_content {
  color: white;
}

.gantt_side_content.gantt_right .task-name,
.gantt_side_content.gantt_right .task-location,
.gantt_side_content.gantt_right .task-zone,
.gantt_side_content.gantt_right .task-area,
.gantt_side_content.gantt_right .task-status,
.gantt_side_content.gantt_right {
  cursor: pointer;
}

.targetEndDate {
  position: absolute;
  border-radius: 2px;
  /* pointer-events: none; */
  cursor: pointer;
  border: 0px;
  z-index: 1;
  opacity: 0.7;
}

.green {
  background-color: rgb(30, 185, 128) !important;
  opacity: 0.6;
}

.red {
  background-color: rgb(254, 105, 105) !important;
  opacity: 1;
}

.orange {
  background-color: rgb(254, 192, 105) !important;
  opacity: 0.8;
  z-index: 0;
}

.orange.critical {
  box-sizing: border-box;
  background-color: rgb(254, 192, 105) !important;
  border-top: 1px solid #ff5252;
  border-bottom: 1px solid #ff5252;
  opacity: 1;
}

.grey {
  background-color: #ccc !important;
  opacity: 0.6;
  z-index: 0;
}

.grey.critical {
  box-sizing: border-box;
  background-color: rgb(254, 192, 105) !important;
  border-top: 1px solid #ff5252;
  border-bottom: 1px solid #ff5252;
  opacity: 1;
}

.task_right,
.task_end_date {
  z-index: 1;
  opacity: 0.6;
}

.gantt_task_progress_wrapper {
  z-index: 1;
  pointer-events: none;
}

.workday-circle {
  background-color: rgb(99, 113, 135);
  border-radius: 50%;
  height: 13px;
  width: 13px;
  z-index: 1;
  pointer-events: none;
}

.gantt_side_content.gantt_right,
.gantt_side_content.gantt_left {
  overflow: visible;
}

.gantt_side_content.gantt_right {
  top: 0px;
}

.task-company {
  color: rgba(0, 0, 0, 0.627);
}

.edit-span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  opacity: 0;
}

.predecessor-label {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 100%;
  flex-grow: 1;
}

.predecessor-column {
  width: 100%;
  display: flex;
}

.predecessor-column:hover .edit-span,
.gantt_cell:focus .predecessor-column .edit-span {
  opacity: 1;
}

.gantt_bar_project .gantt_link_control {
  opacity: 1;
}

.slack {
  position: absolute;
  border-radius: 0;
  opacity: 0.7;

  border: none;
  border-right: 1px solid #b6b6b6;
  margin-left: -2px;
  background: red;
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 5px, #b6b6b6 5px, #b6b6b6 10px);
}

.gantt_task_content_pb {
  font-size: 14px;
  line-height: 100%;
  text-align: left;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: space-around;
  overflow: hidden;
  align-items: flex-start;
  text-transform: capitalize;
  flex-direction: column;
  z-index: 1;
  padding: 5px 0;
  box-sizing: border-box;
}

.gantt_task_content_pb> :last-child {
  margin-top: auto;
}

.gantt_task_content_pb.compact {
  font-size: 12px;
}

.gantt_task_content_pb.comfortable {
  font-size: 14px;
}

.gantt_pd_task_company_logo {
  height: 37.5px;
  width: 37.5px;
}

.gantt_pd_task_text {
  font-weight: 800;
}

.gantt_pd_task_row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gantt_pd_task_row_item {
  padding: 2px 0px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  /* height: 14px; */
}

.gantt_pd_task_box {
  border-radius: 2px;
  background-color: #5252547f;
  padding: 1px;
}

.gantt_pd_task_pace.gantt_pd_task_pace_green {
  background-color: #1eb980;
}

.gantt_pd_task_pace.gantt_pd_task_pace_red {
  background-color: #ff5252;
}

.gantt_pd_drag {
  padding: 0px 17px;
  z-index: 2;
}

.gantt_cell .MuiAvatarGroup-root .MuiAvatar-root {
  height: 25px;
  width: 25px;
  font-size: 15px;
}

.company-avatar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa !important;
  font-size: 9px;
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  text-transform: uppercase;
}

.company-grid-icon {
  aspect-ratio: 1;
  height: max(60%, 1em);
  width: auto;
  border-radius: 50%;
  position: relative;
  vertical-align: middle;
  font-size: 0.7rem;
}

.critical_task {
  background-color: red;
}

.gantt_row.group_row .gantt_tree_content {
  font-weight: bold !important;
}

.gantt_row.group_row,
.gantt_task_row.group_row {
  background-color: #c4c4c430;
}

.highlighted_resource,
.highlighted_resource.odd {
  background-color: rgba(255, 251, 224, 0.6);
}

.custom-project {
  position: absolute;
  height: 11px !important;
  color: #ffffff;
  background-color: #ccc;
}

.custom-project div {
  position: absolute;
}

.project-left,
.project-right {
  top: 6px;
  background-color: transparent;
  border-style: solid;
  width: 0px;
  height: 0px;
}

.project-left {
  left: 0px;
  border-width: 4px 0px 9px 10px;
  border-top-color: transparent;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: #ccc !important;
}

.project-right {
  right: 0px;
  border-width: 4px 10px 9px 0px;
  border-top-color: transparent;
  border-right-color: #ccc;
  border-bottom-color: transparent !important;
  border-left-color: transparent;
}

/** Making task target dependencies bigger on hover **/
.gantt_task_line.gantt_bar_task {
  transition: min-width 0.2s 0s;
  cursor: pointer;
}

.gantt_task_line.gantt_bar_task:hover {
  min-width: 30px;
}

.gantt_task_drag {
  width: 12px;
  display: none;
}

.gantt_task_drag:hover {
  transition: none;
}

.gantt_task_line.gantt_bar_task .gantt_task_drag.task_left {
  left: 0px !important;
  background: url(../../../../public/assets/images/drag-indicator.svg) center;
  border-radius: 12px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.gantt_task_line.gantt_thin_task .gantt_task_drag.task_end_date {
  left: unset;
}

.gantt_task_line.gantt_bar_task .gantt_task_drag.task_right {
  right: 0px !important;
  background: url(../../../../public/assets/images/drag-indicator.svg) center;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.gantt_task_line.gantt_bar_task:not(.gantt_milestone):hover .gantt_task_drag {
  display: block;
  opacity: 1 !important;
}

.highlighted.gantt_task_line.gantt_project.gantt_bar_project {
  clip-path: polygon(-300px -1px,
      -300px 100%,
      -1px 100%,
      5px 70%,
      calc(100% - 5px) 70%,
      100% 100%,
      calc(100% + 300px) 100%,
      calc(100% + 300px) -1px);
}

.highlighted.gantt_bar_task {
  outline: var(--blackhyve-purple) solid 2px !important;
  outline-offset: -1px;
}

.highlighted .gantt_line_wrapper div {
  background-color: var(--blackhyve-purple);
}

.highlighted .gantt_link_arrow_left,
.highlighted .gantt_link_arrow_right {
  border-left-color: var(--blackhyve-purple);
  border-right-color: var(--blackhyve-purple);
}

.highlighted .gantt_link_arrow_left::before,
.highlighted .gantt_link_arrow_right::before {
  content: unset !important;
}

/* .highlight_group_tasks .gantt_task_progress {
  background-color: #d1a8ff;
  opacity: 0.6;
} */
.gantt_task_row.gantt_selected .weekend {
  background-color: #e3e3e3 !important;
}

.gantt_toggle_resources_panel>.gantt_layout_content>div {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}