.background-div {
  background: #fafbfc;
}

.card {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 0.5px solid rgba(210, 210, 219, 0.79);
  border-top: 0px;
}

.card-title {
  display: flex;
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-transform: capitalize;
}

.label-text {
  font-size: 12px;

  text-align: center;
  color: #8e8e90;
  margin: auto !important;
}

.heading-text {
  text-align: initial;
  font-size: 14px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0px !important;
}

.heading-div {
  border: 0.5px solid #b3b3b3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 20px;
}

.child-ul-div {
  padding-left: 20px;
  padding-right: 20px;
}

.value-text {
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.iconImage {
  padding-right: 4px;
}

.iconImage:hover {
  background: none;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  border-radius: 1px;
  text-transform: uppercase;
  padding: 4px 6px;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.green-button {
  border: #1eb980 !important;
  border-radius: 1px;
  text-transform: uppercase;
  background-color: #1eb980 !important;
  color: white !important;
  padding: 4px 6px;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.red-button {
  border: #f44336 !important;
  border-radius: 1px;
  text-transform: uppercase;
  background-color: #f44336 !important;
  color: white !important;
  padding: 4px 6px;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.green-button:hover {
  cursor: pointer;
  background-color: #1eb980;
}

.label-color {
  color: #8e8e90;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.capitalize {
  text-transform: capitalize;
}

.my-panel {
  cursor: pointer;
}

.list-row:hover {
  background-color: #fafafa;
}

.list-row:hover .moreHoriz {
  visibility: visible;
  opacity: 0.3;
}

.scheduler-row:hover .moreHoriz {
  visibility: visible;
  opacity: 0.3;
}

.my-panel:hover {
  background-color: #fafafa !important;
}

.my-panel:hover .moreHoriz,
.my-panel:active .moreHoriz {
  visibility: visible;
  opacity: 0.3;
}

.moreHoriz {
  visibility: hidden;
  cursor: pointer;
}

.show-icon {
  visibility: visible;
}

.loading {
  width: 40px;
  height: 40px;
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.skeleton-row {
  width: 98%;
  height: 41px !important;
  margin: auto;
  border-radius: 0px !important;
}

/* [class*="zone-"]:hover {
    background-color: #feac3140;
} */

.scroll-grid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-grid::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.button {
  font-size: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.background-red {
  background-color: #eb5a46 !important;
}

.background-green {
  background-color: #1eb980 !important;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.text-start {
  text-align: start;
}

.title-text {
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-right: 15px !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid lightGray;
  background-color: #f5f5f5;
  z-index: 99;
}

.page-container {
  margin: auto;
  border: 1px solid lightGray;
  padding: 20px;
  background-color: #fff;
}

.MuiAvatarGroup-root .MuiAvatar-root {
  height: 25px;
  width: 25px;
  font-size: 15px;
}
